// plan
export const financialInstitutionPlan = {
  value: 'FINANCIAL_INSTITUTION_PLAN',
  label: 'Financial Institution Plan',
}

export const packagePlan = {
  value: 'PACKAGE_PLAN',
  label: 'Package Plan',
}

export const usageBasedPlan = {
  value: 'USAGE_BASED_PLAN',
  label: 'Usage Based Plan',
}

export const authorizedUsagePlan = {
  value: 'AUTHORIZED_USAGE_PLAN',
  label: 'Authorized Usage Plan',
}

export const invoicePlans = [
  financialInstitutionPlan,
  packagePlan,
  usageBasedPlan,
  authorizedUsagePlan
]

// invoiceCycle
export const monthly = {
  value: 'MONTHLY',
  label: 'monthly'
}

// status
export const signed = {
  value: 'SIGNED',
  label: 'Signed'
}

export const issued = {
  value: 'ISSUED',
  label: 'Issued'
}

export const expired = {
  value: 'EXPIRED',
  label: 'Expired'
}

export const canceled = {
  value: 'CANCELED',
  label: 'Canceled'
}

