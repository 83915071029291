<template>
  <div v-if='schemas.length > 0 && tableId' class='tw_table_column_setting'>
    <el-popover
      :placement='placement'
      :width='width'
      :visible-arrow='false'
      trigger='click'
      popper-class='tw_table_column_setting_popover'
    >
      <template v-if='isShowSelectAll'>
        <el-checkbox
          class='border_none select_all_checkbox'
          :indeterminate='!checkedAllColumnSetting && tableSettingChosenKeys.length > 0'
          v-model='checkedAllColumnSetting'
        >
          {{ $t('Common.Select All') }}
        </el-checkbox>
      </template>

      <el-checkbox-group
        v-model='tableSettingChosenKeys'
        :class="{'child_checkbox_group' : isShowSelectAll}"
      >
        <div v-for='schema in schemas' :key='schema.key'>
          <template v-if='schema.label'>
            <el-checkbox
              class='border_none'
              :label='schema.key'
              :value='schema.key'
              :disabled="schema.column === 'required'"
            >
              {{ $t(`List.${schema.label}`) }}
            </el-checkbox>
          </template>
        </div>
      </el-checkbox-group>

      <tw-button
        class='menu_button gray'
        type='secondary'
        :size='buttonSize'
        slot='reference'
      >
        {{ $t('Button.Column') }}
      </tw-button>
    </el-popover>
  </div>
</template>

<script>
import TwButton from '@/components/atoms/TwButton';
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'TwTableColumnSetting',
  components: { TwButton },
  props: {
    schemas: {
      required: false,
      type: Array,
      default: () => []
    },
    tableId: {
      required: true,
      type: String
    },
    buttonSize: {
      required: false,
      type: String,
      default: 'small'
      // validator: value => {
      //   return ['small', 'medium', 'large'].indexOf(value) !== -1;
      // }
    },
    width: {
      required: false,
      type: String,
      default: ''
    },
    placement: {
      required: false,
      type: String,
      default: 'bottom-end'
    },
    isShowSelectAll: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      filteredSchemas: [],
      requiredOrDefaultKeys: [],
      tableSettingChosenKeys: []
    };
  },
  computed: {
    ...mapGetters(['getTableSetting']),

    checkedAllColumnSetting: {
      get() {
        return this.tableSettingChosenKeys.length === this.schemas.length;
      },
      set(value) {
        if (value) {
          this.tableSettingChosenKeys = this.schemas.map(item => item.key);
          return true;
        } else {
          this.tableSettingChosenKeys = this.schemas.filter(item => item.column === 'required').map(item => item.key);
          return false;
        }
      }
    }
  },
  created() {
    this.initTableSetting();
  },
  methods: {
    initTableSetting() {
      this.requiredOrDefaultKeys = [];

      this.schemas.forEach((column) => {
        if (column.column === 'required' || column.column === 'default') {
          this.requiredOrDefaultKeys.push(column.key);
        }
      });

      this.tableSettingChosenKeys = _.get(this.getTableSetting(this.tableId), 'chosenKeys', this.requiredOrDefaultKeys);
    }
  },
  watch: {
    tableSettingChosenKeys: function(to) {
      this.$emit('filteredSchemas', this.schemas.filter(schema => to.includes(schema.key)));
      this.$store.commit('SET_TABLE_SETTING', { tableId: this.tableId, tableSetting: { chosenKeys: to } });
    }
  }
};
</script>

<style lang='scss' scoped>
.select_all_checkbox {
  line-height: 36px;
}

.child_checkbox_group {
  padding-left: 8px;
}

.el-checkbox {
  margin-right: 0 !important;
  margin-left: 0 !important;
  width: 100% !important;
  line-height: 36px;
  padding: 0 10px;

  .el-checkbox__label {
    font-size: 14px !important;
  }
}
</style>

<style lang='scss'>
.tw_table_column_setting_popover {
  overflow: auto;
  max-height: 420px !important;
}
</style>
