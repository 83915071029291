<template>
    <el-autocomplete
      class="tw_company_suggest_input"
      :class="{'w-100' : labelWidth}"
      v-model="localValue"
      :fetch-suggestions="companiesAutocompleteSuggestions"
      :placeholder="placeholder"
      :disabled="disabled"
      @select="handleSelect"
      @blur="onBlur"
      @clear="onClear"
      @change="onChange"
      :clearable="clearable"
    >
      <template slot-scope="{ item }" v-if="isAdmin">
        <div>{{ item.companyShortName }}&nbsp;({{ item.companyId }})</div>
      </template>
    </el-autocomplete>
</template>

<script>
import { $api } from '@/store/ApiClient';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import _ from 'lodash';

export default {
  name: 'TwCompanySuggestInput',
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },
    prop: {
      type: String,
      required: false,
      default: 'companyShortName'
    },
    labelWidth: {
      type: String,
      required: false,
      default: '360px'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Please Input'
    }
  },
  data() {
    return {
      companies: [],
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    ...mapGetters(['isAdmin']),
  },
  mounted() {
    if (this.isAdmin) {
      this.fetchAllCompanyName();
    }
  },
  methods: {
    fetchAllCompanyName() {

      const params = {
        lslConfig: {
          serviceCode: 'tw-user-entity-bff-api',
          apiCode: 'get_/v1/companies/initial',
          query: {
            deleteFlg: 0,
            validityStartDate: dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            validityEndDate: dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]')
          }
        }
      };

      $api.request(params)
        .then(response => {
          this.companies = response.companies;
        })
        .catch(err => {
          console.error(err)
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    companiesAutocompleteSuggestions(queryString, cb) {
      var results = queryString ? this.companies.filter(company => company.companyShortName.toLowerCase().indexOf(queryString.toLowerCase()) >= 0) : this.companies;
      // コールバック関数で結果をリターン
      cb(results);
    },
    handleSelect(item) {
      this.localValue = item.companyShortName;
      this.$emit('select', item);
    },
    onBlur(event) {
      this.$emit('blur', _.find(this.companies, ['companyShortName', event.target.value]));
    },
    onClear() {
      this.$emit('clear');
    },
    onChange() {
      this.$emit('change');
    }
  }
};
</script>

<style lang="scss" scoped>
  .el-form-item {

    .tw_company_suggest_input {
      min-width: 290px;

      &.w-100 {
        width: 100% !important;
      }
    }
  }
</style>
