<template>
  <div>
    <tw-header :title="$t('BreadCrumbs.Staff Application Invoices')" />

    <div class='staff_application_invoices_container tw_container'>
      <!-- パンくず 検索エリア -->
      <div class='top_area'>
        <tw-breadcrumbs v-if='breadcrumbs.length' :pages='breadcrumbs' />

        <tw-search-field
          ref='twSearchFieldRef'
          :isShow="items !== null && typeof items !== 'undefined'"
          :filters='filters'
          :params='params'
          :key-for-queries-and-filters='keyForQueriesAndFilters'
          @applyFilters='applyFilters'
        >
          <template v-if="items !== null && typeof items !== 'undefined' && items.length > 0" slot='buttons'>
            <tw-table-column-setting
              :schemas='schemas'
              :tableId='tableId'
              @filteredSchemas='onFilterSchema'
            />

            <el-dropdown trigger='click' placement='bottom' @command='onDropDownClick' :tabindex='-1'>
              <tw-button class='menu_button' type='secondary' size='small' icon='menu'>Menu</tw-button>

              <el-dropdown-menu class='nowrap'>
                <el-dropdown-item command='csv'>{{ $t('Label.CSV Download') }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>

        </tw-search-field>
      </div>

      <tw-table-system
        height='max-content'
        :schemas='filteredSchemas'
        :items='items'
        rowKey='invoiceId'
        :pageSize='params.lslConfig.query.limit'
        :totalCount='totalCount'
        :offset='params.lslConfig.query.offset'
        :serverPagination='true'
        :isLoading='isLoading'
        @paging='onPaging'
      />
    </div>
  </div>
</template>

<script>
import mixinEntityList from '@/utils/mixinEntityList';
import { collapseTransition } from '@/utils/nextFrame';
import TwSearchField from '@/components/organisms/TwSearchField';
import TwTableColumnSetting from '@/components/organisms/TwTableColumnSetting';
import { $api } from '@/store/ApiClient';
import dayjs from 'dayjs';
import _ from 'lodash';
import { invoicePlans } from '@/dictionaries/applicationInvoiceAndContractConstantValues';
import { getYearMonth } from '@/utils/date';
import filters from '@/dictionaries/filters/staff/applicationInvoiceFilters.json';
import schemas from '@/dictionaries/staffApplicationInvoicesSchema.json';
import { GET_INVOICES } from '@/api/target/twInvoiceApi';
import { DEFAULT_PAGENATION } from 'lib-tw-common';

export default {
  name: 'StaffApplicationInvoices',
  mixins: [mixinEntityList, collapseTransition],
  components: { TwTableColumnSetting, TwSearchField },
  props: {
    history: Object
  },
  data() {
    return {
      // USAGE_CHARGES_LAYER: USAGE_CHARGES_LAYER,
      schemas: schemas,
      filters: filters,
      items: null,
      params: {
        lslConfig: {
          ...GET_INVOICES,
          query: {
            companyId: undefined,
            invoiceYearMonth: undefined,
            limit: DEFAULT_PAGENATION.LIMIT,
            offset: 0
          }
        }
      },
      filteredSchemas: [],
      tableId: 'staffApplicationInvoices',
      keyForQueriesAndFilters: 'STAFF_APPLICATION_INVOICE',
      totalCount: 0,
      isLoading: false
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { label: this.$t('BreadCrumbs.Dashboard'), to: '/' },
        { label: this.$t('BreadCrumbs.Staff Application Invoices') }
      ];
    }
  },
  created() {
    // Store/LocalStorageに検索の値が保存されているかをチェック
    // 保存されていれば、applyFiltersでfetchContractsを呼ぶ
    const queriesFromStorage = this.$store.getters.getQueries(this.keyForQueriesAndFilters);
    if (typeof queriesFromStorage === 'undefined') this.fetchInvoices();
  },
  methods: {

    onPaging({ currentPage }) {
      this.params.lslConfig.query.offset = currentPage * this.params.lslConfig.query.limit - this.params.lslConfig.query.limit;
      this.fetchInvoices();
    },

    fetchInvoices() {

      this.isLoading = true;

      const params = _.cloneDeep(this.params);

      if (params.lslConfig.query?.invoiceYearMonth) {
        params.lslConfig.query.invoiceYearMonth = dayjs(params.lslConfig.query.invoiceYearMonth).format('YYYYMM');
      }

      $api.request(params)
        .then(response => {

          if (response?.invoices) {

            this.totalCount = response.totalCount;

            this.items = _.cloneDeep(response.invoices);

            this.items.forEach(item => {
              const planLabel = invoicePlans.find(invoicePlan => invoicePlan.value === item.plan);
              if (planLabel) item.plan = this.$t(`Label.${planLabel.label}`);

              if (item.invoiceYearMonth) item.invoiceYearMonth = getYearMonth(item.invoiceYearMonth);
            });
          }
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          if (this.$refs.twSearchFieldRef) this.$refs.twSearchFieldRef.onClearAllFiltersClick();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    // 検索を行います
    applyFilters(params) {
      this.params = params;
      this.fetchInvoices();
    },

    onFilterSchema(filteredSchemas) {
      this.filteredSchemas = filteredSchemas;
    },

    onDropDownClick(command) {
      if (command === 'csv') {
        const params = _.cloneDeep(this.params);

        params.lslConfig.query.limit = undefined;
        params.lslConfig.query.offset = 0;

        if (params.lslConfig.query?.invoiceYearMonth) {
          params.lslConfig.query.invoiceYearMonth = dayjs(params.lslConfig.query.invoiceYearMonth).format('YYYYMM');
        }

        // 一時的にフロント側でCSVの作成を行う。データ量が増えてきた場合や、freeeへの連携が実現した後は不要になる想定
        $api.request(params)
          .then(response => {

            if (response?.invoices) {
              const items = _.cloneDeep(response.invoices);

              const header = this.schemas.map(item => this.$t(`Label.${item.label}`));
              let csv = '\ufeff' + header.join(',') + '\n';
              const fileName = `application-invoices-${dayjs().format('YYMMDDHHmmss')}.csv`;

              items.forEach(item => {
                const planLabel = invoicePlans.find(invoicePlan => invoicePlan.value === item.plan);
                if (planLabel) item.plan = this.$t(`Label.${planLabel.label}`);

                if (item.invoiceYearMonth) item.invoiceYearMonth = getYearMonth(item.invoiceYearMonth);

                const keys = this.schemas.map(item => item.key);
                keys.forEach(key => {
                  if (_.isNil(item[key])) item[key] = '';
                });

                const pickedItem = _.pick(item, keys);
                const arrayOfItem = Object.keys(pickedItem).map(key => pickedItem[key]);

                csv += arrayOfItem.join(',');
                csv += '\n';
              });

              let blob = new Blob([csv], { type: 'text/csv' });
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
            }
          })
          .catch(err => {
            this.$store.dispatch('SHOW_ALERT', err.message);
          });
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.search_wrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;

  .button_table_setting {
    margin-left: auto;
  }
}
</style>
<style lang='scss'>
.staff_application_invoices_container {
  .search_unit .el-form-item {
    margin-bottom: 0 !important;
  }
}
</style>
