<template>
  <button type='button' :class='classes' :style='style' :disabled='disabled' @click.stop='onClick'>
      <span @click.stop='onIconClick' class='tw-filter-button-icon'>
          <span v-if='isApplied' class='filter-icon-off'>

          <svg width='10' height='10' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M1 1L12.25 12.25' stroke-width='2' stroke-linecap='round' />
          <path d='M12.25 1L1 12.25' stroke-width='2' stroke-linecap='round' />
        </svg>
          </span>

        <span v-else class='filter-icon'>
          <svg width='14' height='14' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>

          <path d='M8.3335 8.33331H3.3335H8.3335Z' />
          <path d='M8.3335 13.3333V8.33331M8.3335 8.33331V3.33331M8.3335 8.33331H13.3335M8.3335 8.33331H3.3335'
                stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
          </svg>
        </span>
    </span>

    <span class='tw-filter-button-text'>
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  name: 'TwFilterButton',
  props: {
    type: {
      type: String,
      default: 'default'
      // validator: value => {
      //   return ['default', 'primary', 'secondary'].indexOf(value) !== -1;
      // }
    },
    size: {
      type: String,
      default: 'medium'
      // validator: value => {
      //   return ['small', 'medium', 'large'].indexOf(value) !== -1;
      // }
    },
    color: {
      type: String
    },
    backgroundColor: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    isApplied: {
      required: false,
      default: false
    }
  },

  computed: {
    classes() {
      return {
        'tw_button': true,
        [`tw_button__${this.type || 'default'}`]: true,
        [`tw_button__${this.size || 'medium'}`]: true,
        'tw_button__icon': !!this.icon,
        [`icon_${this.icon}`]: !!this.icon
      };
    },
    style() {
      return {
        color: this.color,
        backgroundColor: this.backgroundColor
      };
    }
  },
  methods: {
    onClick() {
      this.$emit('onClick');
    },

    onIconClick() {
      this.$emit('onIconClick');
    }
  }
};
</script>

<style lang='scss' scoped>
.tw_button {
  font-weight: bold;
  border-radius: 24px;
  cursor: pointer;
  display: inline-block;
  border: 0.5px solid transparent;
  box-sizing: border-box;
  transition: box-shadow .2s;
  color: $color_white;
  vertical-align: middle;
  @include gray_button_shadow;
  padding: 4px 12px;

  &.square {
    border-radius: 6px;
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &:hover {
    @include gray_button_hover_shadow;
  }

  &:active {
    transition: gray_button_shadow .1s;
    @include button_active_shadow;
  }

  &:focus-visible {
    outline: 0;
    border-color: $color_primary_blue;
    @include button_focused_shadow;

    &:hover {
      @include gray_button_hover_shadow;
    }
  }
}

.tw_button__default {
  background-color: $color_gray_400;
}

.tw_button__primary {
  background-color: $color_dark_blue;
}

.tw_button__secondary {
  color: $color_dark_blue;
  background-color: $color_white;
  border: 0.5px solid $color_gray_300;

  &.black {
    color: $color_black;
  }

  &.gray {
    color: $color_gray_600;
  }
}

.tw_button__small {
  font-size: 14px;
  line-height: 20px;

  .icon {
    width: 16px;
    height: 16px;
  }
}

.tw_button__medium {
  font-size: 16px;
  line-height: 24px;

  .icon {
    width: 20px;
    height: 20px;
  }
}

.filter-icon {
  vertical-align: middle;

  svg {
    fill: $color_gray_600;
    stroke: $color_gray_600;
  }
}

.filter-icon-off {
  vertical-align: middle;
  padding-right: 2px;

  svg {
    fill: $color_white;
    stroke: $color_white;
  }
}
</style>
