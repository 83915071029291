<template>
  <div class='tw_search_field'>
    <el-form class='search_unit compact' ref='searchUnit' :model='filterValues.lslConfig' label-position='top'>
      <div class='search_inner'>
        <div v-if='!loading && isShow' class='row'>
          <div class='filter-wrapper'>
            <div v-for='filter in filterSchema' :key='filter.key' class='filter-inner'>
              <template v-if="filter.filterable === 'default'">
                <el-popover
                  placement='bottom-start'
                  width='max-content'
                  trigger='manual'
                  v-model='shownFilters[filter.key]'
                  class='filter-popover'
                >
                  <div class='popover_inner'>
                    <!--会社名フィルターのポップオーバー-->
                    <div v-if="filter.key === 'companyShortName'">
                      <el-form-item :label='$t(`Label.${filter.label}`)' prop='companyShortName'>
                        <tw-company-suggest-input
                          v-model='companyShortName'
                          :placeholder='filter.placeholder'
                          @select='onSelectCompanyShortName($event, filter)'
                          @blur='onBlurCompanyShortName($event, filter)'
                          @change='onChangeCompanyShortName(filter)'
                          :clearable='false'
                        />
                      </el-form-item>
                    </div>

                    <!--日付間、または月間の期間のフィルターのポップオーバー-->
                    <!--APIコール前にフォーマットし直しは必要のようです-->
                    <div v-else-if="filter.inputType === 'rangeDatepicker'">
                      <el-form-item :label='$t(`Label.${filter.label}`)' prop='rangeDatepicker'>
                        <el-date-picker
                          v-model='rangeDatepicker'
                          :start-placeholder='filter.startPlaceholder'
                          :end-placeholder='filter.endPlaceholder'
                          :type='filter.dateRangeType'
                          :format='filter.dateFormat'
                          :value-format='filter.dateValueFormat'
                          range-separator='to'
                          style='width: max-content'
                          :clearable='false'
                          :popper-class="filter.dateRangeType === 'monthrange' && 'month_date_picker'"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </div>

                    <!--日付、または月のフィルターのポップオーバー-->
                    <!--APIコール前にフォーマットし直しは必要のようです-->
                    <div v-else-if="filter.inputType === 'datepicker'">
                      <el-form-item :label='$t(`Label.${filter.label}`)' :prop='filter.key'>
                        <el-date-picker
                          v-model='filterValues.lslConfig[filter.paramType][filter.key]'
                          :placeholder='filter.placeholder'
                          :type='filter.dateType'
                          :format='filter.dateFormat'
                          :value-format='filter.dateValueFormat'
                          :clearable='false'
                          :popper-class="filter.dateType === 'month' && 'month_date_picker'"
                          style='width: max-content'
                          @change='onChangeMonth($event, filter)'
                        >
                        </el-date-picker>
                      </el-form-item>
                    </div>

                    <!--セレクトボックスのフィルターのポップオーバー-->
                    <template v-else-if="filter.inputType === 'singleSelect'">
                      <el-form-item :label='$t(`Label.${filter.label}`)' :prop='filter.key'>
                        <el-select
                          v-model='filterValues.lslConfig[filter.paramType][filter.key]'
                          :placeholder='filter.placeholder'
                          :clearable='false'
                        >
                          <el-option
                            v-for='option in filter.options'
                            :key='option.value'
                            :label='option.label'
                            :value='option.value'>
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </template>

                    <!--上記以外のテキストのフィルター-->
                    <div v-else-if="filter.key !== 'companyShortName' && filter.inputType === 'text'">
                      <el-form-item :label='$t(`Label.${filter.label}`)' :prop='filter.key'>
                        <el-input type='text'
                                  v-model='filterValues.lslConfig[filter.paramType][filter.key]'
                                  :placeholder='filter.placeholder'
                                  :clearable='false'
                        />
                      </el-form-item>
                    </div>

                    <div class='apply_button_wrapper'>
                      <tw-button
                        type='secondary'
                        size='small'
                        :disabled='getApplyButtonDisable(filter)'
                        @click='onApplyFilterClick(filter)'>
                        Apply
                      </tw-button>
                    </div>
                  </div>

                  <!--会社名フィルターの追加削除ボタン-->
                  <template v-if="filter.key === 'companyShortName'" #reference>
                    <tw-filter-button
                      size='mini'
                      :type="!($_.isNil(params.lslConfig[filter.paramType].companyId)) ? 'primary' : 'secondary'"
                      :class="!($_.isNil(params.lslConfig[filter.paramType].companyId)) ? '' : 'gray'"
                      :isApplied='!($_.isNil(params.lslConfig[filter.paramType].companyId))'
                      @onClick='onShowFilterClick(filter.key)'
                      @onIconClick='onFilterDeleteClick(filter)'
                    >
                        <span>
                          {{ $t(`Label.${filter.label}`) }}

                          <template v-if='getCompanyShortNameFromParams()'>
                            : {{ getCompanyShortNameFromParams() }}
                          </template>
                        </span>
                    </tw-filter-button>
                  </template>

                  <!--日付間、または月間の期間のフィルターの追加削除ボタン-->
                  <template v-else-if="filter.inputType === 'rangeDatepicker'" #reference>
                    <tw-filter-button
                      size='mini'
                      :type="!($_.isNil(getDefaultDateRangeFromParams())) ? 'primary' : 'secondary'"
                      :class="!($_.isNil(getDefaultDateRangeFromParams())) ? '' : 'gray'"
                      :isApplied='!($_.isNil(getDefaultDateRangeFromParams()))'
                      @onClick='onShowFilterClick(filter.key)'
                      @onIconClick='onFilterDeleteClick(filter)'
                    >
                        <span>
                          {{ $t(`Label.${filter.label}`) }}

                          <template v-if='getDefaultDateRangeFromParams()'>
                            :
                            <template v-if="filter.dateRangeType === 'monthrange'">
                              {{ getYearMonth(getDefaultDateRangeFromParams()[0]) }}
                              to
                              {{ getYearMonth(getDefaultDateRangeFromParams()[1]) }}
                            </template>

                            <template v-else>
                              {{ getDefaultDateRangeFromParams()[0] | dateFormat }}
                              to
                              {{ getDefaultDateRangeFromParams()[1] | dateFormat }}
                            </template>
                          </template>
                      </span>
                    </tw-filter-button>
                  </template>

                  <!--上記以外のテキストのフィルターの追加削除ボタン-->
                  <template v-else #reference>
                    <tw-filter-button
                      size='mini'
                      :type="!($_.isNil(params.lslConfig[filter.paramType][filter.key])) ? 'primary' : 'secondary'"
                      :class="!($_.isNil(params.lslConfig[filter.paramType][filter.key])) ? '' : 'gray'"
                      :isApplied='!($_.isNil(params.lslConfig[filter.paramType][filter.key]))'
                      @onClick='onShowFilterClick(filter.key)'
                      @onIconClick='onFilterDeleteClick(filter)'
                    >
                        <span>
                          {{ $t(`Label.${filter.label}`) }}

                          <template v-if="filter.inputType === 'singleSelect' && getSingleSelectFromParams(filter)">
                            : {{ getSingleSelectFromParams(filter) }}
                          </template>

                          <template v-else-if="filter.inputType === 'datepicker'
                          && filter.dateType === 'month'
                          && getDisplayValue(filter.key)"
                          >
                            : {{ getYearMonth(getDisplayValue(filter.key)) }}
                          </template>

                          <template v-else-if='getDisplayValue(filter.key)'>
                            : {{ getDisplayValue(filter.key) }}
                          </template>
                        </span>
                    </tw-filter-button>
                  </template>
                </el-popover>
              </template>
            </div>
          </div>
        </div>

        <div class='right_buttons_wrapper'>
          <template v-if='optionalFilters.length && !loading && isShow'>
            <el-dropdown trigger='click' @command='handleCommandAdditionalFilters'>
              <el-link :underline='false' class='link-filter blue'>
                <span class='icon-filter'>
                  <svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px'
                       viewBox='0 0 18 18' style='enable-background:new 0 0 18 18;' xml:space='preserve'>
<path d='M8.2,15.4c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.3-0.2-0.6V9.8L2.6,3.7C2.4,3.5,2.4,3.2,2.5,3c0.1-0.3,0.3-0.4,0.6-0.4
	h11.7c0.3,0,0.5,0.1,0.6,0.4c0.1,0.3,0.1,0.5-0.1,0.7l-4.8,6.1v4.8c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2H8.2z M9,9.9
	l4.8-6.1H4.2L9,9.9z' />
</svg>
                </span>

                {{ $t('Button.Add Filter') }}
              </el-link>

              <template #dropdown>
                <el-dropdown-menu>
                  <div v-for='filter in filterSchema' :key='filter.key'>
                    <template v-if="filter.filterable === 'optional'">
                      <el-dropdown-item icon='el-icon-circle-plus' :command='filter.key'>
                        {{ $t(`Label.${filter.label}`) }}
                      </el-dropdown-item>
                    </template>
                  </div>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>

          <template v-if='!loading && isShow'>
            <el-link :underline='false' class='link-filter' @click='onClearAllFiltersClick'>
              <span class='icon-filter'>
              <svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px'
                   viewBox='0 0 18 18' style='enable-background:new 0 0 18 18;' xml:space='preserve'>
  <path d='M11.4,8.9L10.6,8l3.7-4.6H5.9L4.7,2.2h10.5c0.3,0,0.5,0.1,0.6,0.4C16,2.8,16,3,15.8,3.3L11.4,8.9z M16.1,17L11,11.9v2.3
    c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2H8.6c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.3-0.2-0.6V8.7L1.1,1.9L1.9,1l15,15
    L16.1,17z' />
  </svg>
              </span>

              {{ $t('Button.Clear Filter') }}
            </el-link>
          </template>

          <template v-if='!loading'>
            <slot name='buttons' />
          </template>
        </div>
      </div>
    </el-form>
    <hr v-if='!loading && isShow'>
  </div>
</template>

<script>
import TwCompanySuggestInput from '@/components/molecules/TwCompanySuggestInput';
import TwButton from '@/components/atoms/TwButton';
import TwFilterButton from '@/components/atoms/TwFilterButton';
import { getYMDEndDateTime, getYMDStartDateTime, getLslConfigParameter } from '@/utils/searchUtil';
import { getYearMonth } from '@/utils/date'
import _ from 'lodash';
import { $api } from '@/store/ApiClient';

export default {
  name: 'TwSearchField',
  components: { TwCompanySuggestInput, TwButton, TwFilterButton },
  props: {
    isShow: {
      required: false,
      type: Boolean,
      default: false
    },
    filters: {
      required: false,
      type: Array,
      default: () => []
    },
    params: {
      required: false,
      type: Object,
      default: () => {
      }
    },
    keyForQueriesAndFilters: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      filterValues: {
        lslConfig: {
          path: {},
          query: {}
        }
      },
      companyShortName: null,
      companyShortNameCache: {},
      rangeDatepicker: null,
      shownFilters: {},
      filterSchema: [],
      activeFilterKeys: [],
      loading: false,
      getYearMonth
    };
  },
  computed: {
    optionalFilters() {
      return this.filterSchema.filter((filter) => filter.filterable === 'optional');
    }
  },
  created() {
    document.addEventListener('click', this.onDocumentClick);

    void this.init();
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onDocumentClick);
  },
  methods: {

    // Functions

    async init() {
      this.loading = true;

      this.filterSchema = _.cloneDeep(this.filters);

      await this.initGetQueries();
      this.initGetFilters();

      this.loading = false;
    },

    async initGetQueries() {
      // ローカルストレージに保存されている検索クエリーを取得
      const queriesFromStorage = this.$store.getters.getQueries(this.keyForQueriesAndFilters);

      if (queriesFromStorage) {
        if (getLslConfigParameter(queriesFromStorage, 'companyId')) {
          await this.fetchCompanyShortName(getLslConfigParameter(queriesFromStorage, 'companyId'));
        }

        this.rangeDatepicker = [];

        if (getLslConfigParameter(queriesFromStorage, 'startAt')) {
          this.rangeDatepicker[0] = getLslConfigParameter(queriesFromStorage, 'startAt');
        }

        if (getLslConfigParameter(queriesFromStorage, 'endAt')) {
          this.rangeDatepicker[1] = getLslConfigParameter(queriesFromStorage, 'endAt');
        }

        this.filterValues.lslConfig.path = queriesFromStorage.path;
        this.filterValues.lslConfig.query = queriesFromStorage.query;

        this.emitApplyFilters();

      } else {
        this.filterValues = _.cloneDeep(this.params);
      }
    },

    initGetFilters() {
      // ローカルストレージに保存されているデフォルトフィルターを取得
      const activeFilterKeysFromStorage = this.$store.getters.getActiveFilters(this.keyForQueriesAndFilters);
      if (activeFilterKeysFromStorage) {
        this.activeFilterKeys = activeFilterKeysFromStorage;

        this.filterSchema.forEach((filter) => {
          if (this.activeFilterKeys.includes(filter.key)) filter.filterable = 'default';
        });

      } else {
        this.activeFilterKeys = this.filterSchema.filter(filter => filter.filterable === 'default').map(filter => filter.key);
      }
    },

    // companyIdに紐づくデータを取得しcompanyShortNameCacheでcompanyShortName持っておく
    async fetchCompanyShortName(companyId) {
      const params = {
        lslConfig: {
          serviceCode: 'tw-user-entity-bff-api',
          apiCode: 'get_/v1/companies/{companyId}',
          path: {
            companyId: companyId
          }
        }
      };

      try {
        const response = await $api.request(params);

        if (response.companies && response.companies.length) {
          this.companyShortNameCache[companyId] = response.companies[0].companyShortName;
        }
      } catch (error) {
        await this.$store.dispatch('SHOW_ALERT', error.message);
      }
    },

    // 親コンポーネントにパラメータと一緒にapplyFiltersをemit
    emitApplyFilters() {
      const mergedParams = _.cloneDeep(this.params);

      mergedParams.lslConfig.query = this.filterValues.lslConfig.query;
      mergedParams.lslConfig.path = this.filterValues.lslConfig.path;

      // 親コンポーネントから渡ってきたlimitとoffsetをセットする
      if (mergedParams.lslConfig.path?.limit) mergedParams.lslConfig.path.limit = this.params.lslConfig.path.limit;
      if (mergedParams.lslConfig.path?.offset) mergedParams.lslConfig.path.offset = this.params.lslConfig.path.offset;

      if (mergedParams.lslConfig.query?.limit) mergedParams.lslConfig.query.limit = this.params.lslConfig.query.limit;
      if (mergedParams.lslConfig.query?.offset) mergedParams.lslConfig.query.offset = this.params.lslConfig.query.offset;

      this.$emit('applyFilters', mergedParams);

      this.$store.commit('SET_QUERIES', {
        key: this.keyForQueriesAndFilters,
        queries: _.cloneDeep({ path: mergedParams.lslConfig.path, query: mergedParams.lslConfig.query })
      });
    },

    showFilter(key) {
      if (this.shownFilters[key]) {
        delete this.shownFilters[key];
      } else {
        this.shownFilters = {};
        this.$set(this.shownFilters, key, typeof this.shownFilters[key] !== 'undefined' || !this.shownFilters[key]);
      }
    },

    hideFilter(key) {
      delete this.shownFilters[key];
    },

    hideAllFilters() {
      this.shownFilters = {};
    },

    handleCommandAdditionalFilters(command) {
      this.filterSchema = this.filterSchema.map(filter => filter.key === command
        ? { ...filter, filterable: 'default' } : filter);
      this.activeFilterKeys = this.filterSchema.filter(filter => filter.filterable === 'default').map(filter => filter.key);

      this.$store.commit('SET_ACTIVE_FILTERS', {
        key: this.keyForQueriesAndFilters,
        filters: _.cloneDeep(this.activeFilterKeys)
      });
    },

    // companyShortNameの存在チェック
    getCompanyShortNameFromParams() {
      const paramsLslConfigCompanyId = getLslConfigParameter(this.params.lslConfig, 'companyId');
      if (this.companyShortNameCache
        && !(_.isNil(paramsLslConfigCompanyId))
        && !(_.isNil(this.companyShortNameCache[paramsLslConfigCompanyId]))) {
        return this.companyShortNameCache[paramsLslConfigCompanyId];
      }
      return null;
    },

    // startAt, endAtの存在チェック
    getDefaultDateRangeFromParams() {
      const startAt = getLslConfigParameter(this.params.lslConfig, 'startAt');
      const endAt = getLslConfigParameter(this.params.lslConfig, 'endAt');

      if (!(_.isNil(startAt)) && !(_.isNil(endAt))) {
        return [startAt, endAt];
      }
      return null;
    },

    getSingleSelectFromParams(filter) {
      if (!(_.isNil(this.params.lslConfig[filter.paramType][filter.key]))) {
        const selectedOption = filter.options.filter(option => option.value === this.params.lslConfig[filter.paramType][filter.key]);
        return selectedOption[0].label;
      }
      return null;
    },

    // 通常の存在チェック
    getDisplayValue(key) {
      if (getLslConfigParameter(this.params.lslConfig, key)) {
        return getLslConfigParameter(this.params.lslConfig, key);
      }
      return null;
    },

    clearDefaultDateRange(paramType) {
      this.filterValues.lslConfig[paramType].startAt = undefined;
      this.filterValues.lslConfig[paramType].endAt = undefined;
      this.rangeDatepicker = null;
    },

    getApplyButtonDisable(filter) {
      if (filter.key === 'companyShortName') {
        return !!(_.isNil(this.companyShortName));
      }

      if (filter.inputType === 'rangeDatepicker' && this.rangeDatepicker) {
        return !!(_.isNil(this.rangeDatepicker[0]) || _.isNil(this.rangeDatepicker[1]));
      }

      return !!(_.isNil(this.filterValues.lslConfig[filter.paramType][filter.key]));
    },

    // Events

    onDocumentClick(event) {
      if (!event.target.classList.contains('filter-popover')) {
        this.hideAllFilters();
      }
    },

    onSelectCompanyShortName(item, filter) {
      this.filterValues.lslConfig[filter.paramType].companyId = item.companyId;
      this.companyShortName = item.companyShortName;
      this.companyShortNameCache[item.companyId] = item.companyShortName;
    },

    onBlurCompanyShortName(validCompany, filter) {
      if (_.isNil(validCompany)) {
        this.onClearCompanyShortName(filter.paramType);

      } else if (
        !(getLslConfigParameter(this.filterValues.lslConfig, 'companyId'))
        || validCompany.companyId !== getLslConfigParameter(this.filterValues.lslConfig, 'companyId')
      ) {

        this.filterValues.lslConfig[filter.paramType].companyId = validCompany.companyId;
        this.companyShortName = validCompany.companyShortName;
      }
    },

    onClearCompanyShortName(paramType) {
      this.filterValues.lslConfig[paramType].companyId = undefined;
      this.companyShortName = null;
    },

    onChangeCompanyShortName(filter) {
      if (!this.companyShortName) {
        this.onClearCompanyShortName(filter.paramType);
      }
    },

    onApplyFilterClick(filter) {

      let pathKey = filter.key;

      if (filter.key === 'companyShortName') {
        pathKey = 'companyId';
      }

      if (filter.inputType === 'rangeDatepicker') {
        if (!(_.isNil(this.rangeDatepicker))) {
          this.filterValues.lslConfig[filter.paramType].startAt = getYMDStartDateTime(this.rangeDatepicker);
          this.filterValues.lslConfig[filter.paramType].endAt = getYMDEndDateTime(this.rangeDatepicker);

        } else {
          delete this.filterValues.lslConfig[filter.paramType].startAt;
          delete this.filterValues.lslConfig[filter.paramType].endAt;
        }
      }

      if (this.filterValues.lslConfig[filter.paramType][pathKey] === '') {
        this.filterValues.lslConfig[filter.paramType][pathKey] = undefined;
      }

      if (_.isNil(this.filterValues.lslConfig[filter.paramType][pathKey])) {
        delete this.filterValues.lslConfig[filter.paramType][pathKey];
      }

      this.emitApplyFilters();
      delete this.shownFilters[filter.key];
    },

    onFilterDeleteClick(filter) {

      let pathKey = filter.key;

      if (filter.key === 'companyShortName') {
        pathKey = 'companyId';
      }

      if (filter.key === 'companyShortName') {
        this.onClearCompanyShortName(filter.paramType);

      }
      if (filter.inputType === 'rangeDatepicker') {
        this.clearDefaultDateRange(filter.paramType);

      } else {
        this.filterValues.lslConfig[filter.paramType][pathKey] = undefined;
      }

      this.emitApplyFilters();
      delete this.shownFilters[filter.key];
    },

    onClearAllFiltersClick() {
      // 全てのフィルターを空にしてデフォルトのフィルター項目に戻す
      this.filterSchema = _.cloneDeep(this.filters);

      for (const searchQuery in this.filterValues.lslConfig.path) {
        if (searchQuery !== 'limit' && searchQuery !== 'offset') {
          this.filterValues.lslConfig.path[searchQuery] = undefined;
        }
      }
      for (const searchQuery in this.filterValues.lslConfig.query) {
        if (searchQuery !== 'limit' && searchQuery !== 'offset') {
          this.filterValues.lslConfig.query[searchQuery] = undefined;
        }
      }
      this.companyShortName = undefined;
      this.rangeDatepicker = undefined;

      this.hideAllFilters();
      this.emitApplyFilters();
    },

    onShowFilterClick(key) {
      this.showFilter(key);
    },

    onFilterHidden(key) {
      // console.debug('Filter ' + key + ' was hidden');

      this.filterValues = _.cloneDeep(this.params);

      if (key === 'companyShortName') {
        this.companyShortName = this.getCompanyShortNameFromParams();
      }
    },

    onChangeMonth(event, filter) {
      this.filterValues.lslConfig[filter.paramType][filter.key] = getYearMonth(event);
    }
  },
  watch: {
    shownFilters: {
      handler: function(to) {
        if (typeof to === 'object') {
          for (const key in to) {
            if (to[key]) {
              // this.onFilterShown(key);

            } else {
              this.onFilterHidden(key);
            }
          }
        }
      },
      deep: true
    }
  }
};
</script>

<style lang='scss' scoped>
.tw_search_field {
  hr {
    border-color: $color_gray_400_skeleton;
    margin-top: 14px;
  }
}

.search_inner {
  display: flex;
  align-items: center;
  position: relative;
}

.filter-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.filter-inner {
  position: relative;
}

.apply_button_wrapper {
  text-align: right;
}

.popover_inner {
  padding: 0 10px 10px;
}

.link-filter {
  color: $color_gray_800;

  .icon-filter {
    vertical-align: middle;

    svg {
      width: 18px;
      height: 18px;
      fill: $color_gray_800;
    }
  }

  &:hover {
    svg {
      fill: $color_dark_blue;
    }
  }

  &.blue {
    color: $color_dark_blue;

    .icon-filter {
      svg {
        fill: $color_dark_blue;
      }
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.loading {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  padding: 16px;
  margin-bottom: 10px;
}
</style>
<style lang='scss'>
.tw_search_field {

  .search_unit .el-form-item {
    margin-bottom: 0 !important;
  }

  .filter-card {
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 1;
  }
}
</style>
