export const serviceCode = 'tw-invoice-api'
export const GET_INVOICES = {
  serviceCode,
  apiCode: 'get_/v1/invoice/search',
};

export const GET_CONTRACTS = {
  serviceCode,
  apiCode: 'get_/v1/contract/search',
};

export const POST_CONTRACT = {
  serviceCode,
  apiCode: 'post_/v1/contract',
};

export const UPDATE_CONTRACT = {
  serviceCode,
  apiCode: 'put_/v1/contract/{contractId}'
};

export const EXPIRE_CONTRACT = {
  serviceCode,
  apiCode: 'put_/v1/contract/expire/{contractId}',
};

export const CANCEL_CONTRACT = {
  serviceCode,
  apiCode: 'put_/v1/contract/cancel/{contractId}',
};
